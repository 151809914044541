<template>
  <orders-table :url="url" order-link="order" :userId="userId"></orders-table>
</template>

<script>
import ordersTable from "@/views/cabinet/order/order-list/OrdersTable";
import { useRouter } from '@core/utils';
import { computed } from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: "RefundsList",
  components: {
    ordersTable
  },
  setup() {
    const {route} = useRouter()
    return {
      userId: computed(() => route.value.params.user_id),
      url: computed(() => route.value.name === 'admin-refunds-user-list' ? 'admin/refunds' : 'refunds-list'),
    }
  }
}
</script>
