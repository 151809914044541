<template>
  <div class="tabs-style">
  <account-role-title role="Buyer" />
  <!-- <breadcrumbs /> -->
  <span class="title">{{ $t("Orders") }}</span>
  <v-tabs v-model="tab" show-arrows class="deals-tabs">
    <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :disabled="index == 0 && $route.name.includes('admin-refunds')"
        @click="$router.push({name: $route.name.includes('admin-refunds') ? tab.adminRefunds : tab.route, query:{t:new Date().getTime()}, params: $route.name.includes('admin-refunds') ? {user_id: userId} : null})"
    >
      <span>{{ $t(tab.title) }}</span>
    </v-tab>
    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <!-- tab component -->
        <component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
  </div>
</template>

<script>
import {ref, watch, computed} from "@vue/composition-api/dist/vue-composition-api";
import {useRouter} from "@core/utils";
import OrdersList from "@/views/cabinet/order/order-list/OrdersList";
import RefundsList from "@/views/cabinet/order/order-list/RefundsList";

export default {
  name: "RefundsAndOrders",
  setup(){
    const { route, router } = useRouter()
    const tabs = [
      { title: 'All Orders', route: 'orders-list', component: OrdersList },
      { title: 'Refund Requests', route: 'refunds-list', adminRefunds: 'admin-refunds-user-list', component: RefundsList },
    ]
    const tab = ref(tabs.findIndex(tab => tab.route == route.value.name || route.value.name == tab.adminRefunds))
    watch(() => route.value.name, (newTab) => tab.value = tabs.findIndex(tab => tab.route == newTab || newTab == tab.adminRefunds))
    return {
      userId: computed(() => route.value.params.user_id),
      tab,
      tabs,
    }
  }
}
</script>
