<template>
  <div class="v-orders">
    <orders-table url="orders-list" order-link="order" />
  </div>
</template>

<script>
import ordersTable from "@/views/cabinet/order/order-list/OrdersTable";
export default {
  name: "OrdersList",
  components: {ordersTable}
}
</script>
